/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I'm Linda, a software developer with a passion for documentation.\nI love creating workflows that work for your whole team.\nI particularly love polishing and refining existing documentation to make it accessible to developers of all levels.\nCheck out some of my ", React.createElement(_components.a, {
    href: "/portfolio"
  }, "past work"), " or schedule a ", React.createElement(_components.a, {
    href: "https://calendly.com/linda-b-robledo"
  }, "free consult call"), " to see if we'd be a good fit."), "\n", React.createElement(_components.h3, null, "Services"), "\n", React.createElement(_components.p, null, "I currently offer the following services."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/full-service-api-docs.pdf"
  }, "End-to-end API Documentation")), "\n", React.createElement(_components.li, null, "Documentation Audit"), "\n", React.createElement(_components.li, null, "Style Guide"), "\n", React.createElement(_components.li, null, "Technical Writing"), "\n", React.createElement(_components.li, null, "Technical Editing"), "\n", React.createElement(_components.li, null, "Open API Specs"), "\n"), "\n", React.createElement(_components.h3, null, "Technical Skills"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Full-stack development (Node & React)"), "\n", React.createElement(_components.li, null, "Git"), "\n", React.createElement(_components.li, null, "HTML & CSS"), "\n", React.createElement(_components.li, null, "Postman"), "\n", React.createElement(_components.li, null, "Static-site-generators (Gatsby, Docusaurus)"), "\n", React.createElement(_components.li, null, "API Documentation SaaS Tools:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Redoc"), "\n", React.createElement(_components.li, null, "Swagger"), "\n", React.createElement(_components.li, null, "ReadMe"), "\n", React.createElement(_components.li, null, "Stoplight"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Textlint"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
